import React from "react";
import mission from "../../contents/mission";


const Mission = () => {
    return (
        <section id="mission" className="container-fluid py-5 stat">
            <div className="row text-center mille">
                <h3 className="fadin grad">Mission</h3>
                <p className=" fadin">
                    {mission}
                </p>
            </div>
        </section>
    )
}

export default Mission