import React from "react";
import menu from "../../contents/menu";


const Menu = () => {
    return (
        <div id="menu">
            <ul>
                {menu.map((item, index) => {
                    return (
                        <li key={index}>
                            <a className="menu_link grad" href={item.url}>
                                {item.name}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Menu