import React from "react";
import logo from "../../assets/img/logo.svg";


const Header = () => {
    return (
        <header className="container-fluid ">
            <div className="row">
                <div className="col-3 align-self-center">
                    <p></p>
                </div>
                <div className="col-6 text-center">
                    <img className="logo_header" src={logo} alt="" />
                </div>
                <div className="col-3 div_burger">
                    <div type="button" id="burger-menu">
                        <span></span>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header