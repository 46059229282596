import React from "react";
import contacts from "../../contents/contacts";


const Footer = () => {
    return (
        <footer id="contatti">
            <div className="row mille bb mb-4 pb-4">
                <div className="col-12 pb-4">
                    <h3 className="fadin grad">Contact</h3>
                </div>

                <div className="d-flex seicento">
                    <div className="d-flex flex-column col-md-12 fadin">
                        <h6>Email</h6>
                        <p><a href="mailto:info@rolesaga.com" className="grad">{contacts.email}</a></p>
                    </div>

                </div>
            </div>
            <div className="row w-100 mille">
                <p className="mb-0 p-0">
                    {contacts.name}
                </p>
                <p className="mb-1">{contacts.iva}</p>
            </div>
        </footer>
    )
}

export default Footer