import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Team from "./components/sections/Team.jsx";
import Footer from "./components/sections/Footer.jsx";
import Mission from "./components/sections/Mission.jsx";
import AssociationMap from "./components/map/AssociationMap.jsx";
import Header from "./components/sections/Header.jsx";
import Menu from "./components/sections/Menu.jsx";
import Hero from "./components/sections/Hero.jsx";
import Supporters from "./components/sections/Supporters.jsx";
import Partnership from "./components/sections/Partnership.jsx";
import Form from "./components/sections/Form.jsx";
import Social from "./components/sections/Social.jsx";
import Artisans from "./components/sections/Artisans.jsx";
import Pricing from "./components/sections/Pricing.jsx";
import AssociationAdvantages from "./components/sections/advantages/AssociationAdvantages.jsx";
import PlayerAdvantages from "./components/sections/advantages/PlayerAdvantages.jsx";

import Container from "./components/container/Container.jsx";

function App() {

    return (
        <Container>
            <Header />
            <Menu />
            <Hero />
             {/*
            <Pricing />
             */}
             {/*
            <AssociationMap />
             */}
             {/*
            <AssociationAdvantages />
             */}
            {/*
            <Partnership />
            */}
{/*
            <PlayerAdvantages />
            */}
            {/*
            <Artisans />
             */}
             {/*
            <Form />
            */}

            {/*
            <Supporters />
            */}
            
           
           
            <Mission />
            <Team />
            
            {/*
            <Social />
            */}
            <Footer />
        </Container >
    );
}

export default App;
