const menu = [
    {
        name: "Mission",
        url: "#mission"
    },
    {
        name: "Team",
        url: "#team"
    },
    {
        name: "Contact",
        url: "#conatti"
    }
]

export default menu