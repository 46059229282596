import luca from "../assets/img/luca.jpg";
import davide from "../assets/img/davide.jpg";
import damian from "../assets/img/damian.jpg";
import federico from "../assets/img/federico.jpg";

const team = [
    {
        avatar: luca,
        name: "Luca ferrari",
        role: "Founder & Mobile Developer",
        description: "Luca Ferrari is an experienced computer programmer and a leader technology with a passion for innovation and resolution problems. With over 8 years of experience in the field, Luca worked in the development of software solutions for smartphones at the forefront for different companies. Luca took a course as Application Mobile Developer at IFOA from 2014 to 2015 and has obtained a certificate as a programmer analyst in the region Emilia-Romagna in 2016. In leisure, Luca loves the role-playing, collecting, watching movies and playing games video games with friends."
    },
    {
        avatar: davide,
        name: "Davide Dinoi",
        role: "Founder & Mobile Developer & UX/UI Design",
        description: "Davide Dinoi is a Frontend Developer with a passion for mobile app. With over 5 years of industry experience technology, but always passionate about computer science. Davide worked in the development of software solutions for smartphones cutting edge for different companies. Davide loves DIY and plant care."
    },
    {
        avatar: damian,
        name: "Damian Marti",
        role: "Co-founder & Web Designer & Marketers",
        description: "Damian Marti is a Web Designer and communication expert digital. With over 5 years of experience as a freelancer has created its own Innovalis reality, and follows companies and free professionals in the various processes of digital communication, from website to social, from placement to sale. It’s a chronic thinker!"
    },
    {
        avatar: federico,
        name: "Federico Grifagno",
        role: "Freelance illustrator",
        description: "I am a freelance illustrator, graduated from the art school of Pisa. My illustrations range from children's book covers to fantasy, multifaceted like me. I love to include in my works something that reminds me of mythology, of any culture, something that in its own small way gives a sense of chaos. My favorite phrase is \"Calm and balance are essential elements in all things; Breaking the rhythm every now and then is important.\""
    },
]

export default team