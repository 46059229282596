import React from "react";
import team from "../../contents/team";
import divider from "../../assets/img/divider.png";
import divider2 from "../../assets/img/divider2.png";


const Team = () => {
    return (
        <section id="team" className="container-fluid py-5 team">
            <img className="divider divider2" src={divider2} alt="" />
            <img className="divider" src={divider} alt="" />
            <div className="row text-center mille pb-5">
                <h3 className="fadin grad">Team</h3>
                <p className=" fadin">
                    Our team represents a concentration of technical skills
                    solid, spanning a wide range of fields and disciplines in the
                    world of technology and design combining creativity and passion
                    for innovation.
                </p>
            </div>

            <div className="row mille d-flex">
                {team.map((item, index) => {
                    return (
                        <div key={index} className="team_card">
                            <div className="card_img">
                                <img className="team_img fadin" src={item.avatar} alt="" />
                                <h3 className="pb-0 fadin">{item.name}</h3>
                                <p className=" fadin">{item.role}</p>
                            </div>
                            <div className="card_content fadin">
                                <p>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default Team