import React, { useRef } from "react";
import logo from "../../assets/img/logo.svg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

document.addEventListener(
    "DOMContentLoaded",
    function () {
        setTimeout(function () {
            document.querySelector("body").classList.add("loaded");
        }, 1000);
    },
    false
);

const Container = ({ children }) => {
    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollTrigger);

    const gsap_container = useRef();

    useGSAP(() => {
        var headerTl = gsap.timeline({
            scrollTrigger: {
                trigger: ".hea",
                start: "98% top",
                end: "bottom bottom",
                toggleActions: "play none reverse  none",
                markers: false,
                onEnter: () => {
                    headerTl.timeScale(1.0);

                    console.log("enter");
                },
                onEnterBack: () => {
                    headerTl.timeScale(2.0);
                    headerTl.reverse();
                    console.log("enter back");
                },
            },
        });

        headerTl.to("header", {
            background: "#ffffff",
            boxShadow: "#3838384d 0px 0px 7px",
            duration: 0.3,
        });
        headerTl.to(".logo_header", {
            delay: -0.2,
            opacity: 1,
            duration: 0.3,
        });
        headerTl.to(" #hamburger span", {
            delay: -0.4,
            background: "#000000",
            duration: 0.4,
        });

        var tlmenu = new gsap.timeline({
            paused: true,
        });
        tlmenu.to("#burger-menu span", {
            rotation: 90,
            transformOrigin: "center",
            duration: 0.7,
        });

        var tl = new gsap.timeline({
            paused: true,
        });
        /*tl.to('.logo-hero', 1.2, {
              opacity:1,
              
              ease: Power4.easeOut
          })*/

        tl.to("#menu", {
            duration: 0.7,
            autoAlpha: 1,
            ease: "power3.inOut",
        });

        tl.to(
            "#menu ul li",
            {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: "power3.inOut",
                stagger: 0.1,
            },
            "<0.1"
        );

        var menuOpen = false;
        var body = document.querySelector("body");
        var closemenu = document.querySelectorAll("#burger-menu, .menu_link");
        for (let i = 0; i < closemenu.length; i++) {
            closemenu[i].addEventListener("click", function () {
                if (!menuOpen) {
                    body.classList.add("hidden");
                    tl.play();
                    tlmenu.play();
                    menuOpen = true;
                } else {
                    body.classList.remove("hidden");
                    tl.timeScale(1.5).reverse();
                    tlmenu.timeScale(1.5).reverse();
                    menuOpen = false;
                }
            });
        }

        gsap.utils.toArray(".fadin").forEach((fadin, i) => {
            //const fad = fadin.querySelector(".fadin");

            ScrollTrigger.create({
                trigger: fadin,

                toggleClass: "fade_active",
                start: "10% 90%",
                stagger: 0.15,
                // this toggles the class again when you scroll back up:
                toggleActions: "play none none none",
                // this removes the class when the scrolltrigger is passed:
                // once: true,
                //markers: true,
                scrub: false,
                markers: false,
            });
        });
    });
    return (
        <div ref={gsap_container} className="gsap_container">
            <div id="preloader" className="preloader">
                <img className="logopreloader" src={logo} alt=""/>
            </div>
            {children}
        </div>
    )
}

export default Container