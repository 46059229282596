import React from "react";
import logo from "../../assets/img/logo.svg";
import divider from "../../assets/img/divider.png";


const Hero = () => {
    return (
        <section className="container-fluid hea full_height">
            <img className="divider" src={divider} alt="" />
            <img className="logo_hero pb-1" src={logo} alt="" />
            <h1 className="text-center">International Live <br />Role-Playing Community for Associations and Players</h1>
            <div className="div_btn">
                <a className="btn1" href="#mission">
                    <span>Who We Are</span>
                </a>
                <a className="btn2" href="#contatti">
                    <span>Contact Us</span>
                </a>
            </div>
        </section>
    )
}

export default Hero